.ParentContainer {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: auto;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 10vw;
}

.Link {
    border: 1px solid black;
    border-radius: 30px;
    padding: 1vw 10vw;
    width: 80%;
    margin-top: 2vw;
    text-align: center;
}
